






















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  components: {
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    ItemPayButton: () => import(/* webpackChunkName: "c-i-pay" */ '@/components/commission/ItemPayButton.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "m-c-f" */ '@/components/media/CommissionFile.vue'),
    MediaCommissionItem: () => import(/* webpackChunkName: "m-c-i" */ '@/components/media/CommissionItem.vue'),
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findItem: 'commission/item/Find',
      listFiles: 'commission/file/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    ITEM_ID(): string {
      return this.$route.params.ITEM_ID;
    },
    item(): ApiResource.CommissionItem | undefined {
      if (!this.COMMISSION_ID || !this.ITEM_ID) return undefined;
      return this.findItem(this.ITEM_ID);
    },
    CURRENCY(): string {
      if (!this.item) return '';
      return this.item.currency.toUpperCase();
    },
    CURRENCY_DIVISOR(): number {
      // Currently, all supported currencies [EUR] are 2 decimal
      return 100;
    },
    files(): ApiResource.CommissionFile[] {
      return this.listFiles(this.COMMISSION_ID)
        .filter((f: ApiResource.CommissionFile) => f.item_id === this.ITEM_ID);
    },
  },
  watch: {
    '$route.params.ITEM_ID': {
      immediate: true,
      handler() {
        this.fetch();
        this.fetchFiles();
      },
    },
  },
  methods: {
    handleError(e: Error|null) {
      this.error = e;
    },
    async fetch() {
      await this.$store.dispatch('commission/item/Find', {
        COMMISSION_ID: this.COMMISSION_ID,
        ITEM_ID: this.ITEM_ID,
      }).catch(this.handleError);
    },
    async fetchFiles() {
      await this.$store.dispatch('commission/file/List', {
        COMMISSION_ID: this.COMMISSION_ID,
        query: {
          item_id: this.ITEM_ID,
        },
      }).catch(this.handleError);
    },
  },
});
