






































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  findCommission: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  listItems: (COMMISSION_ID: string) => ApiResource.CommissionItem[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaCommissionItem: () => import(/* webpackChunkName: "m-c-i" */ '@/components/media/CommissionItem.vue'),
    ItemPayButton: () => import(/* webpackChunkName: "c-i-pay" */ '@/components/commission/ItemPayButton.vue'),
  },
  data() {
    return {
      error: null as any,
      item: null as ApiResource.CommissionItem | null,
      loading: false,
      shouldRetryItemId: null as string|null,
    };
  },
  computed: {
    ...mapGetters({
      findCommission: 'commission/Find',
      listItems: 'commission/item/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission | undefined {
      return this.findCommission(this.COMMISSION_ID);
    },
    items(): ApiResource.CommissionItem[] {
      if (!this.commission) return [];
      return this.listItems(this.COMMISSION_ID);
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
